html, body{
    padding: 0;
    margin: 0;
}
.landing{
    background: #edfffd;
    height: 90vh;
    padding-top: 0vh;
    overflow: hidden;
    
}
.navbar-brand{
    margin-right: 0;
}
.navbar
{
    background-color: #edfffd;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 1vh;
    position: absolute;
    top: 0;
    width: 100vw;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    z-index: 1;
    
}
.navbar-toggler{
    padding: 0;
    border: none;
   
}
.navbar-toggler:focus{
    box-shadow: none;
}
.navbar-toggler svg{
    height: 2rem;
    fill: #061263;
}
.navbar img{
   height: 1.5rem;
   width: 1.5rem;
   margin-right: 0.1rem;
}

.navbar h1{
    font-family: Avenir Black;
    color: #061263;
    font-size: 1.5rem;
    line-height: unset;
    margin-bottom: 0;
    font-weight: 900;
    
}
.navbar .interest{
    margin-right: 0.5rem;
}
.navbar-nav{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 92vh;
}
.navbar-nav .nav-link{
    font-size: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.links{
    width: 100%;
    justify-content: start !important;
    height: 50vh;
    
}

.nav-link
{
    color: #061263;
    font-family: Avenir Black;
    font-weight: 500;
    font-size: 0.8rem;
    
    text-align: center;
}
.nav-link:hover{
    text-decoration: none;
    color: #061263;
}

.launchMainContent{
    display: flex;
    flex-direction: column;
}
.launchMainContent .comingSoon{
    font-size: 0.7rem;
    font-family: 'Courier New', Courier, monospace;
    text-transform:uppercase;
    letter-spacing: 0.2rem;
    color: black;
}
.launchMainContent h1{
    color: #061263;
    /*text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);*/
    font-family: Avenir Black;
    font-weight: 600;
    font-size: 7vh;
    opacity: 1;
    text-align: center;
    line-height: 4.5rem;
}
.launchMainContent h2{
    color: rgba(0,0,0,1);
    
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.1rem;
    text-align: center;
    line-height: 1.8rem;
}
.launchMainContent h3{
    
    color: rgba(3,9,51,1);
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2vh;
    font-weight: bold;
    text-align: center;
    
}

.launchPhone {
    display: none;
  }
.phone {
    width: 358px;
    height: 621px;
    background: rgba(255,255,255,0.9599999785423279);
    opacity: 1;
    display: block;
    border: 7px solid rgba(3,9,51,1);
    border-top-left-radius: 37px;
    border-top-right-radius: 37px;
    border-bottom-left-radius: 37px;
    border-bottom-right-radius: 37px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }
.phoneTop {
    width: 200px;
    height: 36px;
    background: rgba(3,9,51,1);
    opacity: 1;
    position: relative;
    top: 0;
    left: 22%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }
.phoneIcon{
    height: 55vh;
    
  }


.seeHowItWorks .arrow{
    border-radius: 50%;
    height: 5vh;
    width: 5vh;
    box-shadow: inset rgba(0, 0, 0, 0.25) 0px -20px 55px 0px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px -5px 6px, rgba(0, 0, 0, 0.17) 0px 0px 0px, rgba(0, 0, 0, 0.09) 0px -1px 0px;
}
.seeHowItWorks h3{
    
    margin-bottom: 2vh;
} 
.launchMainContent .btn{
    font-size: 1vh;
    background-color: rgba(3,9,51,1);
    border: none;
}
#divider{
    
    border-top: solid 0.05rem #061263;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 2rem;
    
}
.features{
    padding-bottom: 10vh;
    background-color: #edfffd;
}

.features img{
    height: 2rem;
    width: 2rem;
}
.features{
    text-align: center;
}
#featuresHead{
    width: 100%;
    text-align: center;
    font-weight: 600;
    
}
.features h3{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    padding-top: 0.5vh;
    font-size: 1.1rem;
    text-align: left;
}
.features p{
    font-family:system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-top: 1vh;
    font-size: 1rem;
    text-align: left;
    
}
.feature{
    margin-top: 10vh;
    margin-bottom: 10vh;
    padding: 1rem;
    background-color: white;
    border-radius: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    width: 80%;
}

.demo{
    padding-top: 15vh;
    padding-bottom: 15vh;
    background-color: rgb(222 255 249);
}
.demo ul{
    text-align: center;
}
.demo .bi-chevron-left, .demo .bi-chevron-right{
    fill:black;
}
.demo .carousel-indicators [data-bs-target]{
    background-color: rgba(3,9,51,1);
}
.demo h1{
    font-family: 'Nunito';
    color: rgba(3,9,51,1);
    font-size: 3vh;
    font-weight: bold;
}
.demo h2{
    font-size: 1.5vh;
    text-decoration: underline;
    font-family: 'Nunito';
}
.demo .carousel-item img{
    height: 3vh;
    width: 3vh;
}
.demo .carousel-item .row{
    padding-left: 12vw;
    padding-right: 12vw;
}
.demo .carousel-item p{
    font-family: Nunito;
    font-size: 1.2rem;
    text-align: start;
    align-self: center;
    
}
.demo .title{
    text-align: center;
    font-size: 3rem;
    font-weight: Bold;
}
.demo p{
    font-family: Nunito;
    font-size: 1.2rem;
    text-align: center;
}
.demo .carousel-indicators{
    position: unset;
}
.more, .security, .signUp{
    padding-top: 15vh;
    padding-bottom: 15vh;
}
.more, .about{
    background-color: white;
}

.more .title, .security .title, .signUp .title, .about .title{
    font-size: 1.2rem;
    text-align: center;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgb(6 18 99);
    font-weight: 600;
    margin-bottom: 2rem;
}
.more p, .security p, .signUp p{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: rgba(3,9,51,1);
    text-align: center;
    font-size: 1rem;
}
.security{
    background-color: rgba(3,9,51,1);
}
.security .title{
    color: white;
    
}
.security p{
    color: white;
    text-align: left;
    margin-bottom: 0;
    padding-left: 2vw;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.security .list{
  
}
.security svg{
    height: 1.5rem;
    fill: #0effde;

}
.security .row svg{
    height: 1.5rem;
}

.signUp {
   background-color: #edfffd;
}


.signUp .btn{
    font-size: 1rem;
    background-color: #061263;
    border: none;
    color:#0effde;
    border-radius: 1rem;
    font-family: Avenir Black;
}
.quickLinks{
    box-shadow: 0 1rem 3rem rgb(3 9 51 / 50%) !important;
}
.quickLinks:hover{
    color: white;
}
  @media (min-width: 576px) { 
    

  }
  
  
  @media (min-width: 768px) {
    .landing, .features, .demo .title {
        padding-left: 10vw !important;
        padding-right: 10vw !important;
    }
    .landing{
        height: 98vh;
    }
    .arrow{
        margin-top: 2rem;
    }
    .launchPhone{
        display: flex;
        align-items: stretch;
        justify-content: end;

    }
    .navbar img{
        
    }
    .nav-btn{
        display: none !important;
    }
    
    .navbar-nav .nav-link {
        font-size: 0.8rem;
        font-weight: 500;

    }
    .sub{
        background-color: #061263;
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
        border-radius: 1rem;
        color: #0effde;
    }
    .sub:hover{
        color: white ;
    }
    .navbar-nav{
        height: unset;
    }
    .links{
        height: unset;
    }
    .links a{
        width: 33.3%;
    }
    
    #featuresHead{
        font-size: 1.8rem;
        padding-top: 5vh;
    }
    .features h3{
        font-size: 1.5rem;
    }
    .features img{
        height: 2rem;
        width: 2rem;
    }
    .feature{
        width: 50%;
        padding: 2rem;
    }
    .demo{
        padding-top: 20vh;
    }
    .demo .carousel-item .row{
        display: flex;
        justify-content: center;
        
    }
    .carousel{
        padding-top: 10vh !important;
        padding-bottom: 10vh !important;
        
    }
    .carousel-item{
        height: 45vh;
    }
    .demo .title{
        font-size: 4.5rem;
    }
    .more .title, .security .title{
        font-size: 1.8rem;
        padding-right: 5vw;
        
    }

    .more, .security{
        padding-left: 10vw !important;
        padding-right: 10vw !important;
    }
    .more p, .security p{
        font-size: 1rem;
        text-align: center;
    }
    .security .list{
        width: 80%;
    }
    .signUp .btn{
        font-size: 1rem;
        
   }
   .signUp .title{
    font-size: 1.8rem;
   }
   .signUp h1{
    text-align: center;
   }
   .signUp p{
    text-align: center;
    font-size: 1rem;
   }
   .signUp{
    padding-left: 22vw !important;
    padding-right: 22vw !important;
   }
   .interest{
    width: 75%;
   }
}
  
  
  @media (min-width: 992px) {
    
    .landing, .features, .demo .title {
        padding-left: 10vw !important;
        padding-right: 10vw !important;
    }
    .navbar-nav .nav-link{
        font-size: 1rem;
    }
    .sub{
        max-width: 10vw;
    }
    .landing{
        padding-left: 10vw !important;
        padding-right: 10vw !important;
        
    }
    .launchMainContent .btn{
        font-size: 1.2vh;
        
    }
    .landing .bi-caret-right-fill{
        height: 0.8vh;
        width: 0.8vh;
    }
    .carousel-item{
        height: 35vh;
    }
   
    
   }
  
  
  @media (min-width: 1200px) { 
    .navbar h1{
        font-size: 2.2rem;
    }
    .navbar img{
        height: 2.2rem;
        width: 2.2rem;
    }
    .landing, .features, .demo .title {
        padding-left: 15vw !important;
        padding-right: 15vw !important;
    }
    #demoSection .carousel-item h1{
        padding-left: 0vw !important;
        padding-right: 0vw !important;
    }
    .launchMainContent .btn{
        font-size: 1.3vh;
        
    }
    .landing .bi-caret-right-fill{
        height: 1vh;
        width: 1vh;
    }
    .seeHowItWorks h3 {
        padding-right: 1vw;
        margin-bottom: 0;
    }
    .launchMainContent h1{
        font-size: 8vh;
    }
    .demo .carousel-item .row{
        padding-left: 15vw;
        padding-right: 15vw;
    }
    .more .title, .security .title{
        
    }

    
    .more p, .security p{
       padding-left: 0;
        
    }

    
  }
  
 
  @media (min-width: 1400px) {
    .sub{
        max-width: 7vw;
    }
    .launchMainContent .btn{
        font-size: 1.8vh;
        
    }
    #featuresHead{
        font-size: 2.5rem;
        padding-top: 5vh;
    }
    .features h3{
        font-size: 2rem;
    }
    .features img{
        height: 3rem;
        width: 3rem;
    }
    .feature{
        width: 50%;
        padding: 2rem;
    }
    .demo .carousel-item h1,.demo .carousel-item span{
        font-size: 4vh;
    }
    .demo .carousel-item h2{
        font-size: 2.5vh;
    }
    .demo .carousel-item p{
        
        font-size: 2.3vh;
        
    }
    .demo p{
        font-size: 2rem;
    }
    .demo .title{
        font-size: 5.5rem;
    }
    .demo .carousel{
        padding-top: 15vh !important;
    }
    .more .title, .security .title{
        font-size: 2.5rem;
        padding-right: 3vw;
    }
    .signUp .title{
        font-size: 2.5rem;
    }
    
    .more p{
       
        width: 85%;
    }
   }